@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

body {
  background-color: #FFFFFF;
  font-family: "Trirong", monospace;
}

.variant {
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.correctAnswer {
  color: #FFB8F2;
  background: #8bd3dd;
  padding: 1rem;
}

.correctAnswer:after {
  background: #8bd3dd;
  color: #001858;
  visibility: visible;
  position: relative;
  width: 100%;
  height: 100%;

}

.incorrectAnswer {
  background: #FFB8F2;
  color: #fef6e4;
  padding: 1rem;
}

.textBottom {
  color: #FFB8F2
}

.incorrectAnswer:after {
  background: #FFB8F2;
  color: #001858;
  visibility: visible;
  position: relative;
  width: 100%;
  height: 100%;

}
